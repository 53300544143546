import { resolveHtml } from 'helpers/htmlResolver';
import { META_TITLE_POSTFIX } from 'lib/constants';
import { defaultMetaTags } from 'lib/meta';
import {
  Contact,
  ContactUsMetadata,
  ContentPage,
  Homepage,
  OfficeOverviewMetadata,
  OverviewPage,
  VacanciesOverview,
} from 'models';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import { withTranslations } from 'store/translations';
import { getHost } from 'utils/getHost';
import { imageFormatterSet } from 'utils/imageFormatter';
import { hrefLink } from 'utils/normalization/hrefLink';

import fallbackImage from '../../lib/fallbackImage.json';

export const HeadComponent: FC<{
  page?: ContentPage | Homepage | OverviewPage | VacanciesOverview | ContactUsMetadata | OfficeOverviewMetadata;
  locale?: string;
}> = ({ page }): JSX.Element => {
  const router = useRouter();
  const translations = withTranslations();

  if (!page)
    return (
      <Head>
        {defaultMetaTags.map(({ name, content }) => (
          <meta key={name} name={name} content={content} />
        ))}
      </Head>
    );
  const slug = router.query.slug || router.query.mainSubpage || router.query.subcategory;
  let translated = 'careers';

  switch (router.locale) {
    case 'en':
      translated = 'careers';
      break;
    case 'en-epac':
      translated = 'careers';
      break;
    case 'en-us':
      translated = 'careers';
      break;
    case 'en-gb':
      translated = 'careers';
      break;
    case 'da-dk':
      translated = 'karriere';
      break;
    case 'de-de':
      translated = 'karriere';
      break;
    case 'fi-fi':
      translated = 'ura';
      break;
    case 'no-no':
      translated = 'karriere';
      break;
    case 'sv-se':
      translated = 'karriar';
      break;
  }
  const localeString = router.locale === process.env.NEXT_PUBLIC_DEFAULT_LOCALE ? '' : `/${router.locale}`;
  //  console.log('page type ', page.system.type)
  const url =
    page.system.type === 'content_page'
      ? hrefLink(page as ContentPage, slug, true, router.locale, translations)
      : page.system.type === 'overview_page' && page.elements['category']
      ? `/${localeString}/${translations.multiple(page.elements['category']?.value[0]?.codename)}`
      : page.system.type === 'contact_us_metadata'
      ? `${localeString}/${translations.multiple('contactUsUrl')}/${translations.url('peopleUrl')}`
      : page.system.type === 'office_overview_metadata'
      ? `${localeString}/${translations.multiple('contactUsUrl')}`
      : page.system.type === 'contact'
      ? `${localeString}/${translations.multiple('contactUsUrl')}/${translations.url('peopleUrl')}/${
          page.elements['id']?.value[0]
        }`
      : page.system.type === 'vacancies_overview'
      ? `${localeString}/${translated}`
      : `${localeString}`;

  const title = {
    main: page.elements.summaryTitle?.value ? page.elements.summaryTitle?.value : page.elements['display_name']?.value,
    meta: page.elements.metadataTitle?.value,
    og: page.elements.theOpenGraphProtocolOgTitle?.value,
    twitter: page.elements.twitterMetaTagsTwitterTitle?.value,
  };
  const description = {
    main: page.elements.summaryDescription && resolveHtml(page.elements.summaryDescription.value).body,
    meta: page.elements.metadataDescription && resolveHtml(page.elements.metadataDescription.value).body,
    og:
      page.elements.theOpenGraphProtocolOgDescription &&
      resolveHtml(page.elements.theOpenGraphProtocolOgDescription.value).body,
    twitter:
      page.elements.twitterMetaTagsTwitterDescription &&
      resolveHtml(page.elements.twitterMetaTagsTwitterDescription.value).body,
  };

  const image = {
    main:
      page.elements.summaryImageFrontify &&
      imageFormatterSet(page.elements.summaryImageFrontify.value, 2)
        ?.split(/,\n/)[3]
        .replace(/&format=.*/g, '')
        .trim(),
    og:
      page.elements.theOpenGraphProtocolOgImage &&
      imageFormatterSet(page.elements.theOpenGraphProtocolOgImage.value, 2)
        ?.split(/,\n/)[3]
        .replace(/&format=.*/g, '')
        .trim(),
    twitter:
      page.elements.twitterMetaTagsTwitterImage &&
      imageFormatterSet(page.elements.twitterMetaTagsTwitterImage.value, 2)
        ?.split(/,\n/)[3]
        .replace(/&format=.*/g, '')
        .trim(),
  };
  const category = page.elements['category']?.value[0]?.codename;
  const fallbackFrontifyImage = imageFormatterSet(JSON.stringify(fallbackImage), 2)
    ?.split(/,\n/)[3]
    .replace(/&format=.*/g, '')
    .trim();
  return (
    <Head>
      <meta name='category' content={category} />
      {/* TODO: After localization is solved, add hreflang link attributes */}
      {/* <link rel='canonical' href={''} /> */}

      <title>{title.meta?.length ? title.meta : title.main + META_TITLE_POSTFIX}</title>
      <meta name='locale' content={router.locale} />
      <meta name='title' content={title.meta?.length ? title.meta : title.main + META_TITLE_POSTFIX} />
      <meta
        name='description'
        content={description.meta?.length ? resolveHtml(description.meta).body : resolveHtml(description.main).body}
      />
      <meta name='title' content={title.meta?.length ? title.meta : title.main + META_TITLE_POSTFIX} />
      <meta
        name='description'
        content={description.meta?.length ? resolveHtml(description.meta).body : resolveHtml(description.main).body}
      />
      <link rel='canonical' href={getHost() + url} />

      <meta property='og:type' content='article' />
      <meta property='og:url' content={getHost() + url} />
      <meta property='og:title' content={(title.meta?.length ? title.meta : title.main) + META_TITLE_POSTFIX} />
      <meta
        property='og:description'
        content={description.meta?.length ? resolveHtml(description.meta).body : resolveHtml(description.main).body}
      />
      <meta property='og:title' content={(title.meta?.length ? title.meta : title.main) + META_TITLE_POSTFIX} />
      <meta
        property='og:description'
        content={description.meta?.length ? resolveHtml(description.meta).body : resolveHtml(description.main).body}
      />
      <meta property='og:image' content={image.og || image.main || fallbackFrontifyImage} />

      <meta property='twitter:card' content='summary_large_image' />
      <meta
        property='twitter:title'
        content={(title.twitter?.length ? title.twitter : title.main) + META_TITLE_POSTFIX}
      />
      <meta
        property='twitter:description'
        content={
          description.twitter?.length ? resolveHtml(description.twitter).body : resolveHtml(description.main).body
        }
      />
      <meta property='twitter:image' content={image.twitter || image.main || fallbackFrontifyImage} />
    </Head>
  );
};

export const HeadComponentContact: FC<{
  page?: Contact;
  locale?: string;
}> = ({ page }): JSX.Element => {
  const router = useRouter();
  const translations = withTranslations();

  if (!page)
    return (
      <Head>
        {defaultMetaTags.map(({ name, content }) => (
          <meta key={name} name={name} content={content} />
        ))}
      </Head>
    );
  //const slug = router.query.slug || router.query.mainSubpage || router.query.subcategory;

  const localeString = router.locale === process.env.NEXT_PUBLIC_DEFAULT_LOCALE ? '' : `/${router.locale}`;

  const url =
    page.system.type === 'contact'
      ? `${localeString}/${translations.multiple('contactUsUrl')}/${translations.url('peopleUrl')}/${
          page.elements.id.value
        }`
      : `${localeString}`;

  const title = {
    main: page.elements.displayName?.value ? page.elements.displayName?.value : page.elements['display_name']?.value,
    meta: page.elements.displayName?.value,
    og: page.elements.displayName?.value,
    twitter: page.elements.displayName?.value,
  };
  const description = {
    main: page.elements.jobTitle && resolveHtml(page.elements.jobTitle.value).body,
    meta: page.elements.jobTitle && resolveHtml(page.elements.jobTitle.value).body,
    og: page.elements.jobTitle && resolveHtml(page.elements.jobTitle.value).body,
    twitter: page.elements.jobTitle && resolveHtml(page.elements.jobTitle.value).body,
  };

  const image = {
    main:
      page.elements.frontifyImage &&
      imageFormatterSet(page.elements.frontifyImage.value, 2)
        ?.split(/,\n/)[3]
        .replace(/&format=.*/g, '')
        .trim(),
    og:
      page.elements.frontifyImage &&
      imageFormatterSet(page.elements.frontifyImage.value, 2)
        ?.split(/,\n/)[3]
        .replace(/&format=.*/g, '')
        .trim(),
    twitter:
      page.elements.frontifyImage &&
      imageFormatterSet(page.elements.frontifyImage.value, 2)
        ?.split(/,\n/)[3]
        .replace(/&format=.*/g, '')
        .trim(),
  };
  const category = page.elements['category']?.value[0]?.codename.length
    ? page.elements['category']?.value[0]?.codename
    : 'people';

  const fallbackFrontifyImage = imageFormatterSet(JSON.stringify(fallbackImage), 2)
    ?.split(/,\n/)[3]
    .replace(/&format=.*/g, '')
    .trim();
  return (
    <Head>
      <meta name='category' content={category} />
      {/* TODO: After localization is solved, add hreflang link attributes */}
      {/* <link rel='canonical' href={''} /> */}

      <title>{title.meta?.length ? title.meta : title.main + META_TITLE_POSTFIX}</title>
      <meta name='locale' content={router.locale} />
      <meta name='title' content={title.meta?.length ? title.meta : title.main + META_TITLE_POSTFIX} />
      <meta name='description' content={description.meta?.length ? description.meta : description.main} />
      <meta name='title' content={title.meta?.length ? title.meta : title.main + META_TITLE_POSTFIX} />
      <meta name='description' content={description.meta?.length ? description.meta : description.main} />
      <link rel='canonical' href={getHost() + url} />

      <meta property='og:type' content='article' />
      <meta property='og:url' content={getHost() + url} />
      <meta property='og:title' content={(title.meta?.length ? title.meta : title.main) + META_TITLE_POSTFIX} />
      <meta property='og:description' content={description.meta?.length ? description.meta : description.main} />
      <meta property='og:title' content={(title.meta?.length ? title.meta : title.main) + META_TITLE_POSTFIX} />
      <meta property='og:description' content={description.meta?.length ? description.meta : description.main} />
      <meta property='og:image' content={image.og || image.main || fallbackFrontifyImage} />

      <meta property='twitter:card' content='summary_large_image' />
      <meta
        property='twitter:title'
        content={(title.twitter?.length ? title.twitter : title.main) + META_TITLE_POSTFIX}
      />
      <meta
        property='twitter:description'
        content={description.twitter?.length ? description.twitter : description.main}
      />
      <meta property='twitter:image' content={image.twitter || image.main || fallbackFrontifyImage} />
    </Head>
  );
};
