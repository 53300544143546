import { META_OG_SITENAME, META_OG_TYPE, META_TWITTER_CARD } from './constants';

export const defaultMetaTags = [
  { name: 'title', content: 'Ramboll Group' },
  { name: 'og:title', content: 'Ramboll Group' },
  { name: 'twitter:title', content: 'Ramboll Group' },
  { name: 'description', content: '' },
  { name: 'og:description', content: '' },
  { name: 'twitter:description', content: '' },
  { name: 'image', content: '' },
  { name: 'og:image', content: '' },
  { name: 'twitter:image', content: '' },
  { name: 'og:url', content: 'https://ramboll.com' },
  { name: 'og:type', content: META_OG_TYPE },
  { name: 'og:site_name', content: META_OG_SITENAME },
  { name: 'twitter:card', content: META_TWITTER_CARD },
];

export const metaLocale = (locale?: string) => {
  if (locale)
    return locale.replace(/-([a-zA-Z]+)/g, (_match, region: string) => {
      return '_' + region.toUpperCase();
    });
};
